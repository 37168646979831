<template>
  <Page :title="'法律援助'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">法律援助</h1>
          <p class="desc">
            法律援助是国家建立的保障经济困难公民和特殊案件当事人获得必要的法律咨询、代理、刑事辩护等无偿法律服务，维护当事人合法权益、
            维护法律正确实施、维护社会公平正义的一项重要法律制度。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/lawassistance" target="_blank">在线办理</router-link></a-button>
          </div>
          <span class="business-banner-head-icon flyz-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>法律援助申请指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="申请条件">
            <div class="ov_text">
              <p>
                1、申请事项尚未委托律师担任代理人、辩护人，也没有被指派法律援助律师或者法律援助工作人员。
              </p>
              <p>
                2、申请人经济困难且出具经济困难证明。经济困难证明由申请人住所地或者经常居住地乡（镇）人民政府或者街道办事处出具。
              </p>
              <p>无需提供经济困难证明的情形：（11项）</p>
              <p class="sj">
                (1)主张因见义勇为或者保护社会公共利益的行为而产生的民事权益的;(2)享受城乡居民最低生活保障待遇的;(3)享受特困供养待遇的;(4)因疾病、支付基本教育费和突发事件等因素导致生活困难正在接受民政部门求助的;(5)残疾且无固定生活来源的;(6)老年且无固定生活来源的;(7)农民工请求支付劳动报酬、工伤赔偿以及解决劳动保障、社会保险、劳动合同纠纷;(8)年龄在70岁以上以及患有重大疾病的人员;(9)义务兵、供给制学员及军属，执行作战、重大非战争军事行动任务的军人及军属，烈士、因公牺牲军人、病故军人的遗属申请法律援助的;(10)人体器官捐献需要公证的，按照法律援助案件免费办理公证及法律咨询服务;(11)建档立卡的精准扶贫对象免于经济困难审查。
              </p>
              <p>3、法律援助申请事项：</p>
              <p class="sj">
                （1）刑事案件法律援助：没有事项范围，但需提交经济困难证明、身份证明和与案件相关的证明材料即可。
              </p>
              <p>
                犯罪嫌疑人，被告人，公诉案件中的被害人及其法定代理人或者近亲属，自诉案件中的自诉人及其法定代理人，都可以申请法律援助。
              </p>
              <p>（2）民事、行政案件法律援助。(参照援助范围中的规定)</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="申请材料">
            <div class="ov_text">
              <p>
                公民申请代理、刑事辩护等法律援助，应当向法律援助机构提供下列证明、材料：
              </p>
              <p>1、法律援助申请表;</p>
              <p>2、身份证、户口簿或者其他合法有效的身份证明;</p>
              <p>
                3、乡（镇）人民政府、街道办事处、县（市、区）民政部门等有关主管部门出具的能够证明申请人经济困难的材料;
              </p>
              <p>4、与所申请法律援助事项有关的材料;</p>
              <p>
                可以即时办理的法律咨询等法律援助事项，法律援助机构应当即时办理。
              </p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="援助范围">
            <div class="ov_text">
              <p>
                公民有下列需要代理事项之一，因经济困难没有委托代理人的，可以向法律援助机构申请法律援助：
              </p>
              <p>一、民事、行政案件援助范围：</p>
              <p>1.依法请求国家赔偿的；</p>
              <p>2.请求给予社会保险待遇或者最低生活保障待遇的；</p>
              <p>3.请求发给抚恤金、救济金的；</p>
              <p>4.请求给付赡养费、抚养费、扶养费的；</p>
              <p>5.请求支付劳动报酬、经济补偿金或主张其他劳动者合法权益的;</p>
              <p>
                6.请求赔偿因交通事故、工伤事故、医疗事故、产品质量事故或者其他事故造成人身、财产损害的；
              </p>
              <p>
                7.请求赔偿因使用伪劣化肥、农药、种子、农用地膜、农机具等或者因遭受污染造成种植业、养殖业损失和其他损失的；
              </p>
              <p>
                8.主张因见义勇为或者保护社会公共利益的行为而产生的民事权益的；
              </p>
              <p>
                9.残疾人、老年人、未成年人、妇女请求确认继承权或者请求人身损害赔偿的；
              </p>
              <p>10.因生态移民合法权益受到侵害的；</p>
              <p>11.因征地拆迁、土地承包经营权流转，农民合法权益受到侵害的；</p>
              <p>12.因企业改制、重组、兼并，职工合法权益受到侵害的；</p>
              <p>13.女职工在经期、孕期、产期、哺乳期劳动保护权益受到侵害的；</p>
              <p>
                14.因遭受家庭暴力、遗弃、虐待或者配偶有重婚、与他人同居、赌博、吸毒恶习屡教不改等情形，主张解除婚姻关系的；
              </p>
              <p>
                15.职工因工伤认定、工伤待遇或1-4级伤残等级、职业病等事由，提起行政复议或行政诉讼的；；
              </p>
              <p>
                16.军人及军属请求给予优抚待遇，因医疗、交通、工伤事故以及其他人身伤害案件造成人身损害或者财产损失请求赔偿的，因经济适用房、廉租房房屋买卖合同纠纷主张民事权益的，以及涉及军人婚姻家庭纠纷、农资产品质量纠纷、土地承包纠纷、宅基地纠纷以及保险赔付的；
              </p>
              <p>
                17.不服司法机关生效民事和行政裁判、决定，再次进入诉讼程序聘不起律师的；
              </p>
              <p>18.各级党委、政府交办的有一定社会影响的案件；</p>
              <p>19.其他确需法律援助的其他事项。</p>
              <p>二、刑事案件法律援助</p>
              <p>
                1、刑事诉讼中有下列情形之一的，公民可以向法律援助机构申请法律援助;
              </p>
              <p>
                （1）犯罪嫌疑人、被告人因经济困难或者其他原因没有委托辩护人的;
              </p>
              <p>
                （2）公诉案件中的被害人及其法定代理人或者近亲属，自案件移送审查起诉之日起，因经济困难没有委托诉讼代理人的;
              </p>
              <p>
                （3）自诉案件的自诉人及其法定代理人、刑事附带民事诉讼的当事人及其法定代理人，因经济困难没有委托代理人的;
              </p>
              <p>
                2、犯罪嫌疑人、被告人具有下列情形之一没有委托辩护人的，人民法院、人民检察院、公安机关通知法律援助机构指定辩护的，法律援助机构应当指派律师为其提供刑事辩护：
              </p>
              <p>（1）未成年人;</p>
              <p>（2）盲、聋、哑人;</p>
              <p>（3）尚未完全丧失辨认或者控制自己行为能力的精神病人;</p>
              <p>（4）可能被判处无期徒刑、死刑的人;</p>
              <p>
                （5）强制医疗案件中，被申请人或者被告人没有委托诉讼代理人的。
              </p>
              <p>
                3、被告人具有下列情形之一没有委托辩护人的，人民法院通知法律援助机构指定辩护的，法律援助机构应当指派律师为其提供刑事辩护：
              </p>
              <p>（1）共同犯罪案件中，其他被告人已经委托辩护人;</p>
              <p>（2）有重大社会影响的案件;</p>
              <p>（3）人民检察院抗诉的案件;</p>
              <p>（4）被告人的行为可能不构成犯罪;</p>
              <p>（5）有必要指派律师提供辩护的其他情形。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="援助形式">
            <div class="ov_text">
              <p>法律援助的主要形式有：</p>
              <p>1、刑事辩护和刑事代理;</p>
              <p>2、民事诉讼代理;</p>
              <p>3、行政诉讼和行政复议代理;</p>
              <p>4、仲裁代理;</p>
              <p>5、非诉讼法律事务代理;</p>
              <p>6、办理公证证明;</p>
              <p>7、办理司法鉴定;</p>
              <p>8、解答法律咨询、代拟法律文书;</p>
              <p>9、其他形式的法律援助。</p>
              <p></p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="prove" tab="经济困难证明">
            <div class="ov_text">
              <p>法律援助经济困难标准</p>
              <p>国务院《法律援助条例》规定，经济困难标准由省、自治区、直辖市人民政府根据本行政区域经济发展状况和法律援助事业的需要规定。申请人住所地的经济困难标准与受理申请的法律;援助机构所在地的经济困难标准不一致的 ，按照受理申请的法律援助机构所在地的经 济困难标准执行。</p>
              <p>根据《XX省申请法律援助经济困难标准和法律援助补充事项范围》规定，申请法律援助经济困难标准，由现行的城乡居民最低生活保障标准调整为城乡居民最低生活保障标准的2倍。</p>
              <p>经济困难证明由申请人住所地或经常居住地的县级民政部门、乡镇人民政府、街道办事处出具。民政部门出具城乡低保、五保供养证明。其他经济困难证明由乡镇人民政府、街道办事处或代行街道办事处职责的社区出具。经济困难证明必须如实载明申请人家庭人口状况、就业状况、家庭人均收入等情况。</p>
              <p>哪些公民申请法律援助无需提交经济困难证明</p>
              <p>公民申请法律援助符合下列条件之一的 ，无需提交经济困难证明，但应当提供相应证件或者证明材料:</p>
              <p>1.领取失业保险金的城镇居民;</p>
              <p>3.养老院、孤儿院等社会福利机构中由政府供养的人员;</p>
              <p>4.无固定生活来源的残疾人;</p>
              <p>5.符合国家规定的优抚、安置人员;</p>
              <p>6.因见义勇为造成人身损害请求赔偿或者补偿的人员;</p>
              <p>7.因自然灾害等不可抗力造成生活困难的人员;</p>
              <p>8.人民法院指定辩护和给予司法救助的人员;</p>
              <p>9.请求支付劳动报酬或者工伤赔偿的农民工;</p>
              <p>10.途经本省因突发事件或者特殊情况需要援助的人员。</p>
            </div>
          </a-tab-pane>
        </a-tabs>
        </div>
        <Legislation :value="lawassistanceUrl"></Legislation>
        <div class="mt-5 overflow-hidden tab">
          <div class="col-lg-6 col-md-6 col-xs-6 float-left pl-0">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="法援动态">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176891d17178a8381287688095907b2' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="办事表格下载">
                <div  class="tab-pane">
              <Collection  name="post" :query="{ target: '0176892ec2e98a8381287688095908f2' }"
                v-slot="{ collection }"  :limit=9 >
              <Article :refObj="collection"></Article>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 float-right p-0 pl-4">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="政策法规">
                <div  class="tab-pane">
              <Collection  name="law" :query="{ law_ctype: '法律援助' }"
                v-slot="{ collection }"  :limit=9 >
              <Law :refObj="collection"></Law>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="法律援助中心">
              <div class="screen_box">
                <City :value= providerQuery.provider_type @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_name: providerQuery.provider_name,
                  provider_type: providerQuery.provider_type
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="工作人员">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  provider_type: '法律援助中心'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import Article from '../../components/base/Article.vue'
import Law from '../../components/base/Law.vue'
import Legislation from '@/components/business/Legislation'
export default {
  components: {
    Collection,
    Org,
    City,
    Person,
    Article,
    Legislation,
    Law
  },
  data () {
    return {
      lawassistanceUrl: '/lawassistance',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: '',
        provider_type: '法律援助中心'
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    }
  }
}
</script>
