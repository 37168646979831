<template>
  <a-row :gutter="[12, 12]" class="mt-5 org-contain-flow">
    <ul class="contain">
      <li><a href="https://www.renrenlv.com.cn/gsps/qa/t/qa_1_t.html?channelNo=gslzQ001" target="_blank"><i class="icon1"></i><span>智能咨询</span><p>智能引导 方便快捷</p></a></li>
      <li href="javasript:value(0)" target="_blank" @click="() => {
          this.$router.push('/adviceadd')}"><a><i class="icon2"></i><span>留言咨询</span><p>人工客服 快速解答</p></a></li>
      <li href="javasript:value(0)" target="_blank" @click="() => {
          this.$router.push(value)}"><a><i class="icon4"></i><span>在线办理</span><p>直接办理 自助服务</p></a></li>
    </ul>
  </a-row>
</template>
<script>
import base from '@/core/base.js'
export default {
  name: 'intelligent',
  mixins: [base],
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
    }
  }
}
</script>
<style scoped >
.org-contain-flow .contain li{
  width: 31.9%;
  display: inline-table;
}
.org-contain-flow .contain li:nth-child(3){
  margin-right: 0;
  float: right;
}
</style>
